import { Page, Text, Document, StyleSheet, Image, View, Link, Font } from '@react-pdf/renderer';

Font.register({
    family: 'Noto Sans Kannada',
    src: '/fonts/NotoSansKannada-Regular.ttf'
  });
  Font.register({
    family: 'Peddanna',
    src: '/fonts/Peddana-Regular.ttf'
  });

  Font.register({
    family: 'Noto Sans',
    src: '/fonts/NotoSans_Condensed-Regular.ttf'
  });
  Font.register({
    family: 'Noto Sans Gujarati',
    src: '/fonts/NotoSansGujarati_Condensed-Regular.ttf'
  });
  Font.register({
    family: 'Noto Sans Tamil',
    src: '/fonts/NotoSansTamil_Condensed-Regular.ttf'
  });

  const getFontFamily = (text) => {
    if (/[\u0C80-\u0CFF]/.test(text)) {
        return 'Noto Sans Kannada';
    }
    if (/[\u0C00-\u0C7F]/.test(text)) {
        return 'Peddanna';
    }
    if (/[\u0A80-\u0AFF]/.test(text)) {
        return 'Noto Sans Gujarati';
    }
    if (/[\u0B80-\u0BFF]/.test(text)) {
        return 'Noto Sans Tamil';
    }
    return 'Noto Sans';
};

export const PopDetailsPdfGenerator = ({ data, partnerName: name }) => {
    const imgPathUrl = (key) => `https://b-aps1-s3-pop-content.s3.ap-south-1.amazonaws.com/${key}`
    const { stages } = data;
    return (<Document>
        <Page style={styles.body}>
            <View style={styles.pageHeader} fixed >
                <View style={styles.pageHeaderL1} />
                <View style={styles.pageHeaderL2} />
                <View
                    style={{
                        alignContent: 'flex-start',
                        width: '50rem',
                        padding: 20
                    }}>
                    <Image style={styles.headerIcon} src='/static/illustrations/NewLogo.png' />
                </View>
            </View>
            <Text style={{...styles.heading,textAlign:'center',marginVertical: 4}}>Package Of Practice</Text>
            <View style={{paddingHorizontal: 40, flexDirection: 'row', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
            <Text style={{ ...styles.packageName, fontFamily: getFontFamily(`Partner: ${name} || Crop: ${data.cropName} || POP Name: ${data.packageName}`) }}>
                        Partner: {name} || 
                        Crop: {data.cropName} ||
                        POP Name: {data.packageName}
                    </Text>
            </View>
            {stages.map((stage, index) => (
                <View key={stage + index} style={styles.section}>
                    <Text style={{
                        ...styles.heading,
                        marginVertical: 4,
                        fontSize: 14,
                        textAlign: 'left',
                        paddingTop: 10,
                        fontFamily: getFontFamily(stage)
                    }}>{index + 1}. Stage: {stage} </Text>
                    <View style={{paddingTop: 5}}>
                        {data[stage].sequence.map((activity, index) => {
                            const { description, days, fileKey, questionsList } = data[stage][activity];
                           return (<View key={`${index  }ac`}>
                                <View style={{
                                    marginVertical: 4,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingLeft: 15,
                                    paddingTop: 0
                                }}>
                                    <Text style={{
                                    ...styles.heading,
                                        fontSize: 14,
                                        textAlign: 'left',
                                        marginRight: 3,
                                        fontFamily: getFontFamily(activity)
                                    }}>
                                        {String.fromCharCode(index + 65).toLowerCase()}. Activity: {activity}
                                    </Text>
                                    <Text style={{
                                        fontSize: 12,
                                        fontFamily: 'Noto Sans',
                                        textAlign: 'center',
                                        marginTop: 1,
                                    }}>
                                        ({days}  days from sowing date)
                                    </Text>
                                </View>
                                <View style={{
                                    marginVertical: 4,
                                    marginHorizontal: 2,
                                    paddingTop: 0
                                }}>
                                    <Text style={{
                                        fontSize: 12,
                                        paddingLeft: 30,
                                        fontFamily: getFontFamily(description)
                                    }}>
                                        {description}
                                    </Text>
                                </View>
                                <View style={{
                                    marginVertical: 4,
                                    marginHorizontal: 2,
                                }}>
                                    <Image style={styles.activityImg} src={imgPathUrl(fileKey)} />
                                </View>

    {questionsList && questionsList.length > 0 && (
    <>
        <Text style={{
            ...styles.heading,
            textAlign: 'left',
            marginVertical: 4,
            fontSize: 12,
            paddingLeft: 30,
            paddingTop: 5,
            fontFamily: getFontFamily('Questions:')
        }}>
            Questions:
        </Text>
        <View style={{
            textAlign: 'left',
            marginVertical: 4,
            fontSize: 12,
            paddingLeft: 50,
            paddingTop: 5
        }}>
            {questionsList.map((questionObj, qIndex) => (
                <View key={qIndex}>
                    <Text style={{
                        fontSize: 12,
                        fontFamily: getFontFamily(questionObj.question)
                    }}>
                        {qIndex + 1}. {questionObj.question}
                    </Text>
                </View>
            ))}
        </View>
    </>
)}
                            </View>
);
                        })}
                    </View>
                </View>
            ))}
            <View style={styles.footer} fixed >
                <View >
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `Page ${pageNumber} of ${totalPages}`
                    )} />
                </View>
                <View style={{
                    color: '#004752',
                    fontSize: 12,
                    display: 'flex',
                    flexDirection: 'row',
                    textDecoration: 'none',
                }}>
                    <Link style={styles.link} src='#Footnote'>
                        Copyright 2024 | Boomitra & {name} 
                    </Link>
                    {/* <Link style={styles.link} src='https://boomitra.com/privacy-policy/'>
                        Privacy Policy |
                    </Link>
                    <Link style={styles.link} src='https://boomitra.com/terms-conditions/'>
                        Terms & Conditions
                    </Link> */}
                </View>
                <View>
                    <Image style={{
                        width: '40px',
                        height: '40px',
                    }} src='/favicon/favicon.ico' />
                </View>
            </View>
        </Page>
    </Document >
    )
};



const styles = StyleSheet.create({
    body: {
        paddingBottom: 50
    },
    cropName: {
        fontSize: 20,
        textAlign: 'center',
        textTransform: 'capitalize'
    },
    packageName: {
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'capitalize',
        marginVertical: 4,
        fontWeight: 'ultrabold',
        fontFamily: getFontFamily('POP Name')
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
    },
    headerIcon: {
        width: '145px',
        height: '87px',
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 20,
        width: '100%',
        position: 'absolute',
        bottom: 10
    },
    pageNumber: {
        color: '#004752',
        fontSize: 12,
        fontFamily: 'Noto Sans',
        marginTop: 10
    },
    pageHeader: {
        width: '100vw',
        height: '13vh'
    },
    pageHeaderL1: {
        height: '8vh',
        backgroundColor: '#004752',
        padding: 4
    },
    pageHeaderL2: {
        height: '1vh',
        backgroundColor: '#87C45E'
    },
    activityImg: {
        width: 'auto',
        height: '200px',
        paddingLeft: 30
    },
    link: {
        color: '#004752',
        textDecoration: 'none',
        marginHorizontal: 2,
        marginTop: 10,
        fontFamily: 'Noto Sans'
    },
    section: {
        paddingHorizontal: 40
    },
    heading: {
        fontFamily: 'Noto Sans',
    }
});

